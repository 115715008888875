import React, { FC } from 'react';

import styled from 'styled-components';
import { mobile, smMobile, tablet } from 'styles/breakpoints';
import { Reviews } from 'components';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import DogPawList from '../UpsellList';
import CardList from '../CardList';

interface HomeCookingProps {}

const Container = styled.div``;

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  background-color: #f6f4ef;
  @media ${tablet} {
    padding: 1.5rem 2.125rem;
  }
  @media ${smMobile} {
    padding: 1.5rem 0.75rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  max-width: 56.25rem;
  width: 100%;
`;

const ReviewContainer = styled.div`
  padding: 4rem 1rem;
  @media ${tablet} {
    padding: 2rem 1rem;
  }
`;

const HomeCooking: FC<HomeCookingProps> = ({
  paymentSteps,
  userHasBoughtPaperbackBook,
  planSection,
  planSection2,
  PlanSectionComponent,
  dogPawList,
  reviewsSection,
  cardList,
}) => {
  const PlanSectionComponentStyled = styled(PlanSectionComponent)`
    padding: 4rem 1rem;
    @media ${tablet} {
      padding: 2.25rem 1rem;
    }
  `;

  return (
    <>
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <DogPawList {...dogPawList} variant="bone" />
      <PlanSectionComponentStyled {...planSection} />
      <CardList {...cardList} />
      <ReviewContainer>
        <Reviews {...reviewsSection} />
      </ReviewContainer>
      <PlanSectionComponentStyled {...planSection2} />
    </>
  );
};

export default HomeCooking;
